import { ModuleWithProviders, NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ExportTableComponent } from '../component/export-table/export-table.component';
import { LoaderWrapperComponent } from '../component/loader-wrapper/loader-wrapper.component';
import { NgxLoadingModule } from 'ngx-loading';
import { RestrictInputDirective } from '../../shared/directives/restrict-input';
import { StringLimitPipe } from '../pipe/stringLimit.pipe';
import { FilterPipe } from '../pipe/filter.pipe';
import { MatDatepickerModule } from '@angular/material';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter'

@NgModule({
  imports: [
    MaterialModule,
    FlexLayoutModule,
    TranslateModule,
    NgxLoadingModule,
    MatDatepickerModule,
    MatMomentDateModule
  ],
  declarations: [
    ExportTableComponent,
    LoaderWrapperComponent,
    RestrictInputDirective,
    StringLimitPipe,
    FilterPipe
  ],
  exports: [
    MaterialModule,
    FlexLayoutModule,
    TranslateModule,
    ExportTableComponent,
    LoaderWrapperComponent,
    NgxLoadingModule,
    RestrictInputDirective,
    StringLimitPipe,
    FilterPipe
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule
    };
  }
}
