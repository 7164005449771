import { Injectable } from '@angular/core';
import * as Msal from 'msal';
import { Compiler } from '@angular/core';
import {AppConfig} from '../config/app.config'
declare var bootbox: "";
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
@Injectable()

export class MsalService {

    browser=isIE;
    B2CTodoAccessTokenKey = "toolMgt.access.token";

    InternaltenantConfig = {
      tenant: AppConfig.AzureAdInternal.tenant ,
      clientID: AppConfig.AzureAdInternal.clientID ,
      authority:  AppConfig.AzureAdInternal.authority ,
      redirectUri: AppConfig.AzureAdInternal.redirectUri ,
      b2cScopes:AppConfig.AzureAdInternal.b2cScopes ,
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: this.browser
  };
    constructor(private _compiler: Compiler){
      this._compiler.clearCache();
    }
   
   
        /*
     * Internal Login Configuration
     */
    myMSALObj = new Msal.UserAgentApplication( this.InternaltenantConfig.clientID,this.InternaltenantConfig.authority, this.ExtCallback,
       {
      navigateToLoginRequestUrl: false,
      validateAuthority: false,
      redirectUri: this.InternaltenantConfig.redirectUri
      }) 
    

    public Internallogin():void{
        this.myMSALObj.authority = "https://login.microsoftonline.com/" + this.InternaltenantConfig.tenant ;
        this.myMSALObj.loginRedirect(this.InternaltenantConfig.b2cScopes);
      }

    private ExtCallback(errorDesc: any, token: any, error: any, tokenType: any) {
        if (token) {
            localStorage.setItem('msalToken', JSON.stringify(token));
          }
        else {
          if (errorDesc.indexOf('AADB2C90118') > -1){
          window.location.href="https://electroluxaadb2cdev.b2clogin.com/electroluxAADB2Cdev.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_ForgotPassword&client_id=9dc70528-d6f2-49d8-ade7-11e193b5fddd&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fsamplecicdwebapp.azurewebsites.net%2Flogin&scope=openid&response_type=id_token&prompt=login";
        //  window.location.href = "https://elxsupplier.b2clogin.com/ElxSupplier.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_ForgotPassword&client_id=946e98e4-9460-4512-8768-331a27fda54b&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fsupplierportal.electrolux-na.com%2Flogin&scope=openid&response_type=id_token&prompt=login";
          }
            console.log(error + ":" + errorDesc);
        }
      }
     
   
    internallogout(): void {
     this.ClearApplicationLocalCache();
      this.myMSALObj.logout();
  };
    isLoggedIn(): boolean {
        return this.myMSALObj.getUser() != null;
    };
    ClearApplicationLocalCache(){
      localStorage.removeItem('msalToken');
      localStorage.removeItem('LastUserLoginOn');
      localStorage.removeItem('CurrentUser');
      localStorage.removeItem('menuItems');
      localStorage.removeItem('msalUserEmail');
      localStorage.removeItem('SupplierUserEmail');
      localStorage.removeItem('loginAsSupplier');
      localStorage.removeItem('SelectedDropDown');
      localStorage.getItem('previousRoute');
      window.localStorage.clear();
    }
    getUserEmail():string{
      var email="";
      if(Number(JSON.parse(localStorage.getItem('loginAsSupplier')))==1)  email=  JSON.parse(localStorage.getItem('SupplierUserEmail')) 
      else 
       email=  JSON.parse(localStorage.getItem('msalUserEmail'))
      return email;
    }
    getmsalUserEmail(){
      var email= this.getUser().idToken['emails'];
      if(email==undefined && email==null) return this.getUser().idToken['preferred_username'];
      else  return email[0];
    }
   setUserEmailtoLocalStorage(){
    var currentUser= JSON.parse(localStorage.getItem('CurrentUser'));
    if(currentUser==undefined || currentUser==null || currentUser.userloginname==undefined){
    localStorage.setItem('msalUserEmail', JSON.stringify(this.getmsalUserEmail()));
    }
   }
    getUserName(): string{
      var name="";
      name= this.getUser().idToken['name'];
      if(name==undefined || name==null || name=="")
      name = this.getUser().idToken['extension_CompanyName'];
      return name;
    }

    getUser(){
      return this.myMSALObj.getUser()
    }
  getIsUserLogin(){

  }
    get authenticated() {
        return this.myMSALObj.acquireTokenSilent(this.InternaltenantConfig.b2cScopes).then(t => !!t);
      }
      
}
