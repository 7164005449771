import { InjectionToken } from '@angular/core';

import { IAppConfig } from './iapp.config';

export let APP_CONFIG = new InjectionToken('app.config');

export const AppConfig: IAppConfig = {
  version: '?v1.1.1',
  routes: {
    login: 'login',
    mainPage: '',
    requestNewLogin: 'request-new-login',
    error404: '404'
  },
  Snackbar: {
    verticalPosition: 'bottom',
    horizontalPosition: 'end',
    duration: 20000
  },
  patterns: {
    email: '/^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$/'
    //"^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$"
    //"[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
    //"^(('[\w-\s]+')|([\w-]+(?:\.[\w-]+)*)|('[\w-\s]+')([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)"
  },
  validations: {
    name: { minLength: 3, maxLength: 30 },
    email: { minLength: 3, maxLength: 100 },
    country: { minLength: 3, maxLength: 32 },
    phone: { minLength: 12, maxLength: 12 },
    city: { minLength: 1, maxLength: 30 },
    pincode: { minLength: 4, maxLength: 8 },
    address: {
      address1: { minLength: 1, maxLength: 32 },
      address2: { minLength: 1, maxLength: 32 },
    },
    username: { minLength: 3, maxLength: 30 },
    qty: { minLength: 1, maxLength: 5 },
    errors: {
      tokenExpire: 'Session expired, please login again',
      orderWrongEntry: 'Please validate the data on the screen',
      savedublicateKPI: 'Please validate the data on screen as the KPI already exists.',
      savedublicateEntry: 'Please validate the data on screen as the data already exists.',
      savedublicateKPISeq: 'Please validate the data on screen as the KPI Sequence already exists.',
      savedublicateObjSeq: 'Please validate the data on screen as the Objecective Sequence already exists.',
      deletedublicateEntry: `Please validate data on screen as the entry is already in use.  All existing sub-entries must be deleted before this record can be deleted.`,
      outOfStock: 'Please remove or substitute the items that are not available',
      errorAgainstOrderNo: 'Error occurred while fetching order details, please contact system administrator',
      errorAgainstOrderStatus: 'Error occurred while fetching order status, please contact system administrator',
      networkError: 'Error occurred, please contact system administrator',
      qty: 'Quantity should be greater then 0'
    }
  },
  pageSizeOptions: {
    pageSizeWP: [50, 100, 200, 500]
  },
  SignDropDown: [
    { viewValue: '<', value: '<' },
    { viewValue: '>', value: '>' }
  ],


  GetYearDropDown() {
    let startYear: number = 2020;
    let currYear = new Date().getFullYear();
    let arrYear: any[] = new Array();
    for (var i = startYear; startYear <= currYear; startYear++) {
      arrYear.push({ viewValue: startYear.toString(), value: startYear.toString() });
    }
    return arrYear;
  },


  QuarterDropDown: [
    { viewValue: '1st Quarter', value: '1@3' },
    { viewValue: '2nd Quarter', value: '4@6' },
    { viewValue: '3rd Quarter', value: '7@9' },
    { viewValue: '4th Quarter', value: '10@12' }
  ],

  BooleanKey: [{ value: true, viewValue: 'Yes' }, { value: false, viewValue: 'No' }],


  AzureAdInternal: {
    // DEV UAT  
    // tenant: "d2007bef-127d-4591-97ac-10d72fe28031",// Replace this with your Tenant id 
    // clientID: 'd19ad616-d87d-4cf3-b028-318c7608566c',// Replace this with your client id 
    // authority: "https://login.microsoftonline.com/d2007bef-127d-4591-97ac-10d72fe28031",
    // //redirectUri: "http://localhost:4200/home",
    // redirectUri: "https://uat-performancedashboardwebapp.azurewebsites.net/home",
    // b2cScopes: ["https://uat-performancedashboardwebapp.azurewebsites.net/user_impersonate"],

    //   //PROD
      tenant: "d2007bef-127d-4591-97ac-10d72fe28031",// Replace this with your Tenant id 
      clientID: '9364eeac-2dc1-4576-9399-3a2ef63a22c8',// Replace this with your client id 
      authority:  "https://login.microsoftonline.com/d2007bef-127d-4591-97ac-10d72fe28031",
      redirectUri: "https://performancedashboard.electrolux-na.com/home",  //Prod
      //redirectUri:  "http://localhost:4200/home", //DEV
      b2cScopes:["https://as-na-prod-performancedashboardapi.azurewebsites.net/user_impersonate"],
  },
  AllfactoryId: 134,  //136 : UAT and 134 : Production,

  FileLibrary: "naprodperformancedash", //"nadevstgperformancedash"// UAT //"naprodperformancedash"  //Production
  //HelpFilePath: 'https://nadevstgperformancedash.blob.core.windows.net/help/KPITrainingGeneralUsers.pptx'  //UAT
  HelpFilePath: 'https://naprodperformancedash.blob.core.windows.net/help/KPITrainingGeneralUsers.pptx'  //Prod
};
