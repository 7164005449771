import { Component, Inject, OnInit, Input, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { APP_CONFIG, AppConfig } from '../../config/app.config';
import { IAppConfig } from '../../config/iapp.config';
import { ProgressBarService } from '../shared/progress-bar.service';
//import { UserService } from '../../shared/sevices/user.service';
import { MatSidenav } from '@angular/material';
import { NavService } from '../shared/nav.service';
import {MsalService} from '../../services/msal.service';
import { Observable, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})

export class NavComponent implements OnInit {

  appConfig: any;
  menuItems: any[];
  progressBarMode: string;
  currentLang: string;
  sideNav: Boolean = true;
  userInfo: any;
  fullName: string;
  isSpecialCondition: boolean;
  isSidebarEnable: boolean;
  loginAsSupplier:number=2;  // 1: for Supplier 2: Electrolux User
  //@Input('sidenav') sidenav: MatSidenav;
  constructor(@Inject(APP_CONFIG) appConfig: IAppConfig,
    private progressBarService: ProgressBarService,
    private translateService: TranslateService,
    private router: Router,
    private navService: NavService,
    private msalService: MsalService
  ) {
    this.appConfig = appConfig;
    this.loginAsSupplier = JSON.parse(localStorage.getItem('loginAsSupplier'));
  }

  ngOnInit() {
    this.isSidebarEnable =true;
    this.fullName = this.userFullName();
    this.sideNav=true;
    this.currentLang = this.translateService.currentLang;
    if (window.innerWidth < 768) {
      this.sideNav = false;
    }
  }

  goToPage(pagename) {
    this.router.navigateByUrl('/' + pagename);
  }

  logout(): void {
    if(this.loginAsSupplier==1){
      localStorage.removeItem('msalUserEmail');
      localStorage.setItem('msalUserEmail', JSON.stringify(this.msalService.getmsalUserEmail()));
      localStorage.setItem('loginAsSupplier', JSON.stringify(2));
    }
   this.msalService.internallogout();
  }
 
  userFullName(){
    let name = this.msalService.getUserName();
    return name;
  }
  setSideNavState() {

    this.navService.setSideNavState(this.sideNav);
    this.sideNav = !this.sideNav;
  }
  logoutAsSupplier(){
    localStorage.removeItem('msalUserEmail');
    localStorage.removeItem('SelectedDropDown');
    localStorage.setItem('msalUserEmail', JSON.stringify(this.msalService.getmsalUserEmail()));
    localStorage.setItem('loginAsSupplier', JSON.stringify(2));
    window.location.reload();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 768) {
      this.sideNav = false;
    }
    if (event.target.innerWidth > 768) {
      this.sideNav = true;
    }
  }
}
