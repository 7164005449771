import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard as AuthGuard } from './shared/services/auth/msalGuard.service';
import {PreviousRouteRecorder} from './services/PreviousRouteService';

// Route Configuration.
const routes: Routes = [
  { path: '', loadChildren: () => import(`./pages/login/login.module`).then(m => m.LoginModule) },
  { path: 'login', loadChildren: () => import(`./pages/login/login.module`).then(m => m.LoginModule) },

  { path: 'home' ,canActivate: [AuthGuard],canDeactivate: [PreviousRouteRecorder], loadChildren: () => import(`./pages/Administration/dashboard/dashboard.module`).then(m => m.dashboardModule)},
  { path: 'TermsandCondition',canDeactivate: [PreviousRouteRecorder],loadChildren: () => import(`./pages/termsand-condition/termsand-condition.module`).then(m => m.termsAndConditionModule)},
  { path: 'administration/Announcement', canActivate: [AuthGuard],canDeactivate: [PreviousRouteRecorder], loadChildren: () => import(`./pages/Administration/dashboard/dashboard.module`).then(m => m.dashboardModule)},
  { path: 'administration/Announcment/Add',canActivate: [AuthGuard],canDeactivate: [PreviousRouteRecorder],loadChildren: () => import(`./pages/Administration/dashboard/add-announcement/add-announcement.module`).then(m => m.addAnnouncementModule)},
  { path: 'administration/Announcment/Edit/:Id',canActivate: [AuthGuard],canDeactivate: [PreviousRouteRecorder],loadChildren: () => import(`./pages/Administration/dashboard/add-announcement/add-announcement.module`).then(m => m.addAnnouncementModule)},
  { path: 'home/View/:Id',canActivate: [AuthGuard],canDeactivate: [PreviousRouteRecorder],loadChildren: () => import(`./pages/Administration/dashboard/add-announcement/add-announcement.module`).then(m => m.addAnnouncementModule)},
  { path: 'contact-us',canDeactivate: [PreviousRouteRecorder],loadChildren: () => import(`./pages/contact-us/contact-us.module`).then(m => m.ContactUsModule)},
  { path: 'administration/ManageUsers',canActivate: [AuthGuard],canDeactivate: [PreviousRouteRecorder],loadChildren: () => import(`./pages/Administration/manageuser/manageuser.module`).then(m => m.manageuserModule)},
  { path: 'administration/ManageUsers/Add',canActivate: [AuthGuard],canDeactivate: [PreviousRouteRecorder],loadChildren: () => import(`./pages/Administration/manageuser/add-user/add-user.module`).then(m => m.adduserModule)},
  { path: 'administration/ManageUsers/Edit/:userId',canActivate: [AuthGuard],canDeactivate: [PreviousRouteRecorder],loadChildren: () => import(`./pages/Administration/manageuser/add-user/add-user.module`).then(m => m.adduserModule)},
  { path: 'KPI/Setup',canActivate: [AuthGuard],canDeactivate: [PreviousRouteRecorder],loadChildren: () => import(`./pages/KPI/kpi.module`).then(m => m.KpiModule)},
  { path: 'KPI/Value',canActivate: [AuthGuard],canDeactivate: [PreviousRouteRecorder],loadChildren: () => import(`./pages/KPI/Value/setup_Value.module`).then(m => m.setup_ValueModule)},
  { path: 'administration/Function',canActivate: [AuthGuard],canDeactivate: [PreviousRouteRecorder],loadChildren: () => import(`./pages/Administration/function/function.modue`).then(m => m.FunctionModule)},
  { path: 'administration/Objective',canActivate: [AuthGuard],canDeactivate: [PreviousRouteRecorder],loadChildren: () => import(`./pages/Administration/objective/objective.modue`).then(m => m.ObjectiveModule)},
  { path: 'administration/ProvidedBy',canActivate: [AuthGuard],canDeactivate: [PreviousRouteRecorder],loadChildren: () => import(`./pages/Administration/providedBy/providedBy.modue`).then(m => m.providedByModule)},
  { path: 'Help',canActivate: [AuthGuard],canDeactivate: [PreviousRouteRecorder],loadChildren: () => import(`./pages/help/help.module`).then(m => m.helpModule)},
  
];

  @NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
  })
export class AppRoutingModule { }




