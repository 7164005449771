import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
    name: 'filter',
})
@Injectable()
export class FilterPipe implements PipeTransform {
    transform(data: any[], field: Array<any>, value: string): any[] {
        value = value.trim().toLowerCase();
        let items = data;
        if(items.constructor === Object) {
            items = items['data'];
        }
        if (!items) {
            return [];
        }
        if (!field || !value) {
            return items;
        }
        
        return items.filter(singleItem =>{
            let filter = false;
            for (var i = 0; i < field.length; i++) {
                let name = singleItem[field[i]] ? singleItem[field[i]].toString().toLowerCase() : '';
                if(name.indexOf(value) !== -1) {
                    filter = true;
                    break;
                }
              }
            return filter;
        });
    }
}