import { Component, Inject, OnInit, HostListener } from '@angular/core';
//import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG } from '../../config/app.config';
import { IAppConfig } from '../../config/iapp.config';
import { ProgressBarService } from '../shared/progress-bar.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { NavService } from '../shared/nav.service';
//import { UserService } from '../../shared/sevices/user.service';

@Component({
  selector: 'app-outer-sidebar',
  templateUrl: './outer-sidebar.component.html',
  styleUrls: ['./outer-sidebar.component.scss']
})

export class OuterSidebarComponent implements OnInit {

  constructor() {
  }
  ngOnInit() {
  }
}