import {Component, OnInit} from '@angular/core';
//import {TranslateService} from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
  currentLang: string;

  //constructor(private translateService: TranslateService, private router: Router) {
 //}
 constructor(private router: Router) {}

  ngOnInit() {
    //this.currentLang = this.translateService.currentLang;
  }

  goToPage(pagename) {
    this.router.navigateByUrl('/' + pagename);
  }

}
