import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe  } from '@angular/common';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {  HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from './app.translate.factory'; 
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalService }  from './services/msal.service';
import { AppRoutingModule } from './app-routing.module';
import { ProgressBarService } from './core/shared/progress-bar.service';
import { MsalGuard as AuthGuard } from './shared/services/auth/msalGuard.service';
import { TokenInterceptor  } from "./services/token.interceptor";
import { SharedModule } from './shared/modules/shared.module';

import {TimeoutInterceptor,DEFAULT_TIMEOUT} from './services/TimeoutInterceptor';
import {FileSelectDirective} from "ng2-file-upload";

import { AppComponent } from './app.component';
import { APP_CONFIG, AppConfig } from './config/app.config';
import { FlexLayoutModule } from "@angular/flex-layout";
import { CoreModule } from './core/core.module';
import { SharedConfirmDialogModule } from './shared/modules/sharedConfirmDialog.module';
import{ MessageDialogComponent} from './shared/dialog/message-dialog/message-dialog.component';

import {GlobalErrorHandler} from './services/error.interceptor';
import {ServerErrorInterceptor} from './services/ServerErrorInterceptor';
import { PreviousRouteRecorder } from './services/PreviousRouteService';
import { NgxBarcodeModule } from 'ngx-barcode';
import { EditorModule } from'@progress/kendo-angular-editor';
import {SafeHtmlPipe} from "./services/safeHtml";


@NgModule({
  declarations: [AppComponent,FileSelectDirective,SafeHtmlPipe],
  imports: [
    BrowserModule,SharedConfirmDialogModule,NgxBarcodeModule,EditorModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,
    FormsModule, FlexLayoutModule, ReactiveFormsModule, OwlDateTimeModule,
    OwlNativeDateTimeModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule.forRoot(),
    AppRoutingModule,CoreModule
  ],
  providers: [MsalService, AuthGuard,DatePipe,PreviousRouteRecorder,
   { provide: HTTP_INTERCEPTORS,useClass: TokenInterceptor,multi: true, deps: [ProgressBarService]}, 
    { provide: APP_CONFIG, useValue: AppConfig },
    [{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
    [{ provide: DEFAULT_TIMEOUT, useValue: 30000 }],
    [{provide: ErrorHandler, useClass: GlobalErrorHandler},{ provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true }]
],
  exports: [SharedModule],
  bootstrap: [AppComponent],
  entryComponents:[MessageDialogComponent]
})
export class AppModule { }
