import { Injectable } from '@angular/core';
import { utils, write, WorkBook } from 'xlsx';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
import * as jsPDF from 'jspdf'
import 'jspdf-autotable';

@Injectable({ providedIn: 'root' })
export class ExportFileService {

    constructor() { }
    public downloadFile(data: any, exportFileName: string, acceptedFileFormat: string = 'xlsx', formatData: any, orientation: string) {
        let useFormat = acceptedFileFormat.toLocaleLowerCase();
        let fileFormat = [
            {
                name: 'csv',
                type: 'text/csv;charset=utf-8;'
            },
            {
                name: 'xlsx',
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            },
            {
                name: 'pdf',
                type: 'application/pdf'
            }
        ];
        let _format = _.find(fileFormat, { name: useFormat });
        switch (_format.name) {
            case 'csv':
                this.convertToCSV(data, exportFileName, _format.name, _format.type);
                break;
            case 'xlsx':
                this.convertToXLSV(data, exportFileName, _format.name, _format.type);
                break;
            case 'pdf':
                this.convertToPDF(data, exportFileName, _format.name, _format.type, formatData,  orientation);
        }
        // if (useFormat == 'csv') {
        //     var blob = new Blob([fortmatData], { type: _format.type });
        //     if (navigator.msSaveBlob) { // IE 10+
        //         navigator.msSaveBlob(blob, this.createFileName(exportFileName, _format.name))
        //     } else {
        //         var link = document.createElement("a");
        //         if (link.download !== undefined) { // feature detection
        //             // Browsers that support HTML5 download attribute
        //             var url = URL.createObjectURL(blob);
        //             link.setAttribute("href", url);
        //             link.setAttribute("download", this.createFileName(exportFileName, _format.name));
        //             //link.style = "visibility:hidden";
        //             document.body.appendChild(link);
        //             link.click();
        //             document.body.removeChild(link);
        //         }
        //     }
        // }
    }

    private convertToXLSV(json: any[], excelFileName, format, type) {
        let _fileName = excelFileName.substring(0,26);
        const ws_name = _fileName || 'SomeSheet';
        const wb: WorkBook = { SheetNames: [], Sheets: {} };
        const ws: any = utils.json_to_sheet(json);
        wb.SheetNames.push(ws_name);
        wb.Sheets[ws_name] = ws;
        const wbout = write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });
        const buf = new ArrayBuffer(wbout.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== wbout.length; ++i) {
            view[i] = wbout.charCodeAt(i) & 0xFF;
        };
        this.saveAsExcelFile(buf, excelFileName, format, type);
    }

    private convertToCSV(objarray: any, excelFileName, format, type) {
        var array = typeof objarray != 'object' ? JSON.parse(objarray) : objarray;
        var str = '';
        var row = "";
        for (var index in objarray[0]) {
            //Now convert each value to string and comma-separated
            row += index + ',';
        }
        row = row.slice(0, -1);
        //append Label row with line break
        str += row + '\r\n';

        for (var i = 0; i < array.length; i++) {
            var line = '';
            for (var index in array[i]) {
                if (line != '') line += ','
                line += JSON.stringify(array[i][index]);
            }
            str += line + '\r\n';
        }
        //return str;
        this.saveAsExcelFile(str, excelFileName, format, type);
    }

    private saveAsExcelFile(buffer: any, fileName: string, format, type): void {
        let fileFormat = "." + format;
        const data: Blob = new Blob([buffer], {
            type: type
        });
        let _fileName = fileName.split(' ').join('_');
        FileSaver.saveAs(data, _fileName + '_export_' + new Date().getTime() + fileFormat);
    }

    convertToPDF(rows: any[] = [], fileName, format, type, columns,  orientation) {
        let fileFormat = "." + format;
        let customColumnStyles = {};
        columns.forEach((item)=>{
            if(item['columnWidth']) {
                customColumnStyles[item['dataKey']] = {columnWidth: item['columnWidth']};
            }
        });
        let fileOrientation = [{
            orientation: 'l',
            headerLeftSpace: 40, //415
            headertopSpace: 30
        },
        {
            orientation: 'p',
            headerLeftSpace: 40,
            headertopSpace: 30
        }
        ]
        let cuttentFormat = orientation == 'L' || (columns.length > 8) ? fileOrientation[0] : fileOrientation[1];
        var doc = new jsPDF(cuttentFormat.orientation, 'pt');
        var totalPagesExp = "{total_pages_count_string}";
        var pageContent = function (data) {
            // HEADER
            doc.setFontSize(14);
            doc.text(fileName, cuttentFormat.headerLeftSpace, cuttentFormat.headertopSpace);
            var str = "Page " + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp;
            }
            doc.setFontSize(10);
            doc.text(str, 420, doc.internal.pageSize.height - 10);
        };

        doc.autoTable(columns, rows, {
            theme: 'grid',
            addPageContent: pageContent,
            styles: {
                cellPadding: 4,
                fontSize: 8,
                valign: 'middle',
                lineColor: [192, 192, 192], //Silver gray
                overflow: 'linebreak',
                tableWidth: 'auto',
                //columnWidth: 80 //63.5
                //columnWidth: 'auto', //wrap,
                
            },
            headerStyles: {
                cellPadding: 4,
                lineWidth: 0.1,
                lineColor: [192, 192, 192], //Silver gray
                valign: 'top',
                fontStyle: 'bold',
                halign: 'left', //'center' or 'right'
                fillColor: [26, 188, 156], //green
                textColor: [255, 255, 255], //White
                fontSize: 8
            },
            columnStyles: customColumnStyles,
            startY: false,
            margin: { horizontal: 40, top: 50, bottom: 50 },
        });
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        let _fileName = fileName.split(' ').join('_');//.substring(0,26);
        doc.save(_fileName || 'B2B_exportPdf' + fileFormat);
    };
}