import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
    selector: 'message-dialog',
    templateUrl: './message-dialog.component.html',
    styleUrls: ['../dialog.scss']
})
export class MessageDialogComponent implements OnInit {
    items: string;
    title: string;
    action: string;
    actionName: string;
    multiAction: Array<any> = [];
    nextLine: boolean = false;

    constructor(
        private dialogRef: MatDialogRef<MessageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        let _data = data;
        this.title = _data.title;
        this.items = _data.description;
    }

    ngOnInit() { }

    close(action = ''): void {
        this.dialogRef.close(action);
    }
}
