import { Injectable } from '@angular/core';
import { 
  HttpEvent, HttpRequest, HttpHandler, 
  HttpInterceptor, HttpErrorResponse 
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {MsalService} from '../services/msal.service';
import { NavService } from '../core/shared/nav.service';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {

    constructor(private msalService: MsalService,private navService: NavService){
      }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 400) {
           this.msalService.internallogout();
        }
         else {
          return throwError(error);
        }
      })
    );    
  }
}