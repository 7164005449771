import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class PageMenus {

  //API_ENDPOINT = environment.isCanada;

  constructor() { }

  getDefaultMenus() {
    let data = [
      {
        path: 'home',
        data: {
          menu: {
            title: 'Home',
            icon: 'dashboard'
          },
        }
      },{
        path: 'administration',
        data: {
          menu: {
            title: 'Administration',
            icon: 'dashboard'
          },
        },
        children: [
          {
            path: 'ManageUsers',
            data: {
              menu: {
                title: 'Manage Users',
              },
            },
          },
          {
            path: 'Announcement',
            data: {
              menu: {
                title: 'Announcement',
              }
            }
          },
          {
            path: 'Function',
            data: {
              menu: {
                title: 'Function',
              }
            }
          },
          {
            path: 'Objective',
            data: {
              menu: {
                title: 'Objective',
              }
            }
          },
          
          {
            path: 'ProvidedBy',
            data: {
              menu: {
                title: 'Provided By',
              }
            }
          },
        ],
        
      },
  
      
    {
      path: 'KPI',
      data: {
        menu: {
          title: 'KPI',
          icon: 'account_box'
        },
      },
      children: [
        {
          path: 'Setup',
          data: {
            menu: {
              title: 'Setup',
            },
          },
        },
        {
          path: 'Value',
          data: {
            menu: {
              title: 'Value',
            }
          }
        },
      ],
  },
 
{
  path: 'Help',
  data: {
    menu: {
      title: 'Help',
      icon: 'dashboard'
    },
  }
}
    ];

    return data;
  }
}