import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {LegalsignoffInterface  } from '../shared/interfaces/interfaces';
import { Compiler } from '@angular/core';

@Injectable({
  providedIn: 'root'
})  
export class UserService {
  
  isSpecialCondition = new BehaviorSubject(false);
  isSidebarEnable = new BehaviorSubject(true);
  privateKeys: any;
  API_ENDPOINT = environment.baseUrl;

  constructor(private http: HttpClient,private _compiler: Compiler) {this._compiler.clearCache(); }

  getMenus(): any {
    if (!localStorage.getItem('menuItems')) { return {}; }
    return JSON.parse(localStorage.getItem('menuItems'));
}

getUserLoginDetails(userName:string,lastloginOn:string,updateLastLogin:number): Observable<any> {

  return this.http
  .get<any[]>(this.API_ENDPOINT+"api/Menu/GetApplicationMenu?userName="+userName+'&lastloginOn='+lastloginOn+'&updateLastLogin='+updateLastLogin)
  .pipe(map(data => data));
}
UpdateLegalSignOff(data:LegalsignoffInterface): Observable<any> {
  return this.http
  .post(this.API_ENDPOINT+"api/Menu/UpdateLegalSignOff",data)
  .pipe(map(data => data));
}

}