import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from '../../services/common.service';


@Component({
    selector: 'confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['../dialog.scss']
})
export class ConfirmDialogComponent implements OnInit {
    description: string;
    title: string;
    action: string;
    actionName: string;
    multiAction: Array<any> = [];
    nextLine: boolean = false;

    constructor(
        private dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        let _data = data;
        this.description = _data.description;
        this.title = _data.title;
        this.action = _data.action || '';
        this.actionName = _data.actionName;
        this.multiAction = _data.multiAction || [];
        this.nextLine = _data.nextLine || false;
        
        if(this.multiAction.length === 0) {
            this.multiAction.push({action: this.action, actionName: this.actionName})
        }
    }

    ngOnInit() { }

    close(action = ''): void {
        this.dialogRef.close(action);
    }
}
