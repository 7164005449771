import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxLoadingModule } from 'ngx-loading';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ConfirmDialogComponent } from '../dialog/confirm-dialog/confirm-dialog.component';
import { MaterialModule } from './material.module';
import { MessageDialogComponent } from '../dialog/message-dialog/message-dialog.component';

@NgModule({
  imports: [
    MaterialModule,
    FlexLayoutModule,
    TranslateModule,
    NgxLoadingModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [
    ConfirmDialogComponent,
    MessageDialogComponent
  ],
  exports: [
    ConfirmDialogComponent,
    MessageDialogComponent
  ],
  entryComponents: [
    ConfirmDialogComponent,
    MessageDialogComponent
  ]
})

export class SharedConfirmDialogModule {
}