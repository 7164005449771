import { ErrorHandler, Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private http: HttpClient) { }
  API_ENDPOINT = environment.baseUrl;
  handleError(error) {
  console.log(error);
  }
}