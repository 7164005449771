import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ProgressBarService } from './core/shared/progress-bar.service';
import{MsalService} from './services/msal.service';
import { MatSnackBar, MatSidenav } from '@angular/material';
import { AppConfig } from './config/app.config';
import { UserService } from './services/UserService';
import{NavService} from './core/shared/nav.service';
import { DatePipe  } from '@angular/common';

declare const Modernizr;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class  AppComponent implements OnInit {
    title = 'Electrolux Supplier Portal';
    _onRouteChange: any;
    _redirectToHome: boolean = false;
    @ViewChild('sidenav', {static: false}) sidenav: MatSidenav;
    navMode: string = 'side';
    isOpen: boolean = true;
    isAuthenticated: boolean = false;
    isLoading: boolean = true;
    progressBarMode: string;
    isSpecialCondition: boolean;
    objUserdetail:any;
    MenuOptions: any[] = [];
    isUserLoggedIn:boolean=false;
    pageLoader:boolean=false;
    previousUrl: string;
    isSidebarEnable: boolean;
    hidecomponent:boolean=false;

    constructor(
      private router: Router,
      private progressBarService: ProgressBarService, private userService: UserService,
      private navService: NavService,
      private datePipe: DatePipe,
      private msalService:MsalService
    ){
      if (window.location.href.indexOf("print") != -1)
      {
         this.hidecomponent=true; 
        
      }
      else{ this.hidecomponent=false;
      }
      this.previousUrl=   localStorage.getItem('previousRoute');
     
      if(msalService.isLoggedIn()){
      this.pageLoader=true;
      this.msalService.setUserEmailtoLocalStorage();
      this.getMenuItemsSource();
      }
      
    }

 ngOnInit() {
        this.isSpecialCondition = false;
        if (window.innerWidth < 768) {
          this.navMode = 'over';
          this.isOpen = false;
        }
  }
  getMenuItemsSource(){
    var email= this.msalService.getUserEmail()
    var lastloginOn= JSON.parse(localStorage.getItem('LastUserLoginOn'));
   var updatelastLogin=0;  // 0: false, 1 :true
    if(lastloginOn ==undefined || lastloginOn ==null ||lastloginOn =='') {
      lastloginOn=this.transformDate(new Date);
      updatelastLogin=1;
      localStorage.setItem('LastUserLoginOn', JSON.stringify(lastloginOn)); 
    } 

    this.userService.getUserLoginDetails(email,lastloginOn,updatelastLogin)
    .subscribe(
      restItems => {
         this.objUserdetail =restItems;
         var objUser= this.objUserdetail.user;
         localStorage.removeItem('CurrentUser');
         localStorage.setItem('CurrentUser', JSON.stringify(objUser)); 
         if(this.objUserdetail.menuitems!=undefined && this.objUserdetail.menuitems!=null){
          this.objUserdetail.menuitems.forEach((option) => {
            if(option.parentid==0 && option.menulevel==0){
              let obj = { "path": '', "children": [] };
  
              obj['key'] =option.path;
              let child= this.objUserdetail.menuitems.filter(a=>a.parentid==option.menuid);
              if(child !=null){
                child.forEach((item) => {
                  let childobj={"key":''}; 
                  childobj["key"]=item.path;
                  obj['children'].push(childobj)
                });
              }
              this.MenuOptions.push(obj);
            }
         });
         localStorage.setItem('menuItems', JSON.stringify(this.MenuOptions)); 
         this.isUserLoggedIn=true;
         this.pageLoader=false;
         }
      }
      )
  }
  transformDate(myDate) {
    return this.datePipe.transform(myDate, 'MM-dd-yyyy hh:mm:ss'); //whatever format you need. 
}
    @HostListener('window:resize', ['$event'])
    onResize(event) {
      if (event.target.innerWidth < 768) {
        this.navMode = 'over';
        this.isOpen = false;
      }
      if (event.target.innerWidth > 768) {
        this.navMode = 'side';
        this.isOpen = true;
      }
    }
    goToPage(pagename) {
      this.router.navigateByUrl('/' + pagename);
    }
  }
  

  