import { Component, OnInit, Input, AfterContentInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
//import { LocalCacheService } from '../../shared/sevices/local-cache.service';

@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.scss']
})
export class MenusComponent implements OnInit, AfterViewInit {
  @Input('menus') menuItems;
  //constructor(private cache: LocalCacheService, private router: Router) { }
  constructor(private router: Router) { }
  ngOnInit() {

  }
  ngAfterViewInit() {
    this.sidebarJquery();
  }
  private sidebarJquery() {
    setTimeout(() => {
      let subMenuVal = "#sidebar > ul > li > button.submenu[aria-expanded!='false']";
      // start ----> open tab on page load
      if ($("#sidebar ul > li > a").hasClass("active")) {
        $("a.active").parent("li").parent("ul").attr("aria-expanded", "true").show().prev().attr("aria-expanded", "true");
      }
      // end ----> open tab on page load
      // start ---> open and close tab on click
      $("#sidebar > ul > li > button").click(function () {
        if ($(this).next().hasClass('list-unstyled')) {
          if ($(this).attr("aria-expanded") == 'false') {
            $(this).attr("aria-expanded", "true");
          } else {
            $(this).attr("aria-expanded", "false");
          }
          // start ---> close previous tab click on another tab
          $(subMenuVal).toArray().forEach((item) => {
            if (item.textContent != $(this).text()) {
              item.setAttribute("aria-expanded", "false");
              $('#' + item.nextElementSibling.id).hide();
            }
          });
          // end ---> close previous tab click on another tab         
          $(this).next('ul').toggle();
        } else {
          // if click on single option close the another tab
          $(subMenuVal).attr("aria-expanded", "false").next().hide();
        }
      });
      // end ---> open and close tab on click   
    }, 1000);
  }
  openUrl(data) {
    if (data.popup) {
      let url = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port;
      //this.popupwindow(`${url}/#/${data.link}`, 'Parts Info', window.innerWidth - 100, window.innerHeight - 100)
      this.popupwindowDual(`${url}/#/${data.link}`, 'Parts Info', window, window.innerWidth - 100, window.innerHeight - 100)
    } else {
      window.open(data.link, '_blank');
    }
  }

  popupwindow(url, title, w, h) {
    var left = (screen.width / 2) - (w / 2);
    var top = 100;//(screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
  }

  popupwindowDual(url, title, win, w, h) {
    var y = win.top.outerHeight / 2 + win.top.screenY - (h / 2)
    var x = win.top.outerWidth / 2 + win.top.screenX - (w / 2)
    return win.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + y + ', left=' + x);
  }
  
  subMenuClick(items, item) {
    let extraCache = ['saved-order','order-history','claims-history'];
    let url = `/${items}/${item}`;
    if (url != this.router.url) {
      //if(extraCache.indexOf(item) !== -1) {
      //  this.cache.setItem('customers', '');
      //} else {
      //  this.cache.setItem(item, '');
     // }
    }
  }

}
