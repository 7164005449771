import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';

@Component({
    selector: 'snack-bar-component-single-line',
    templateUrl: './toaster-with-component-single-line.html',
    styleUrls: ['./toaster-with-component-single-line.scss']
})
export class ToaterComponentSingleLineComponent {
    data: Array<any>;
    activeClass: string;
    constructor(private snackBar: MatSnackBar, @Inject(MAT_SNACK_BAR_DATA) public items: Object) {
        this.data = items['data'];
        this.activeClass = items['class']
    }

    close() {
        this.snackBar.dismiss();
    }
}