import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import {  Router } from '@angular/router';
import { MsalService } from '../services/msal.service';
import { Observable} from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private msalService: MsalService,private _router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    // add authorization header with jwt token if available
    let msalToken = JSON.parse(localStorage.getItem('msalToken'));
    if ( msalToken) {
        request = request.clone({
            setHeaders: { 
                Authorization: `Bearer ${msalToken}`
            },
            headers: new HttpHeaders({
              'Content-Type':'application/json; charset=utf-8',   //to resolve Pen Testing Issue
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache',
              'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
              'If-Modified-Since': '0',
              'X-Frame-Options':'DENY',  //to resolve Pen Testing Issue
              'Content-Security-Policy':"frame-ancestors 'self'",  //to resolve Pen Testing Issue
              'Arr-Disable-Session-Affinity':'true'
            }),
            url: request.url.replace('http://', 'https://')  //to resolve Pen Testing Issue
        });   
    }
    return next.handle(request);
}
}