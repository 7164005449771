import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { AppConfig } from '../../config/app.config';
import { snackbar as snackbarInterface } from '../../shared/interfaces/interfaces';
import { ExportFileService } from './exportFile.service';
import * as _ from 'lodash';
import { BehaviorSubject } from '../../../../node_modules/rxjs';
import { Location } from '@angular/common';
//import { ToaterComponentComponent } from '../component/toaster-with-component/toaster-with-component';
import { ToaterComponentSingleLineComponent } from '../component/toaster-with-component-single-line/toaster-with-component-single-line';

@Injectable({ providedIn: 'root' })

export class CommonService {
    classes: Array<any> = ["success", "error", "info", "warning"];
    isPageLading = new BehaviorSubject(false);
    innerWidth: number;
    innerHeight: number;
    emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;///^[A-Za-z0-9]+(\.[_A-Za-z0-9]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,15})$/;
    account_max: number = 10;
    account_min: number = 5;
    PO_max: number = 14;

    constructor(private snackBar: MatSnackBar, private exportFileService: ExportFileService, private _location: Location) { }

    getUser(): any {
        if (!localStorage.getItem('user')) { return {}; }
        return JSON.parse(localStorage.getItem('user'));
    }

    openSnackBar(obj: snackbarInterface): void {
        let items = obj.data;
         let statusInfo = items.status.toLowerCase();
        let classes = this.classes.indexOf(statusInfo) != -1
        let data = { data: [items.message], class: classes ? [statusInfo] : 'info' };
        this.snackBar.openFromComponent(ToaterComponentSingleLineComponent, {
            data: data,
            duration: items.duration || AppConfig.Snackbar.duration,
            verticalPosition: AppConfig.Snackbar.verticalPosition,
            horizontalPosition: AppConfig.Snackbar.horizontalPosition,
            panelClass: classes ? [statusInfo] : 'info'
        });
    }

    openSnackbarWithComponent(obj: any) {
        let items = obj;
        let statusInfo = items.status.toLowerCase();
        let classes = this.classes.indexOf(statusInfo) != -1
        let data = [...obj.data];
        /*this.snackBar.openFromComponent(ToaterComponentComponent, {
            data: data,
            duration: items.duration || AppConfig.Snackbar.duration,
            verticalPosition: AppConfig.Snackbar.verticalPosition,
            horizontalPosition: AppConfig.Snackbar.horizontalPosition,
            panelClass: classes ? [statusInfo] : 'info'
        });*/
    }

    makeJsonFromTable(tableID) {
        var tbl = $('#' + tableID)
        var tblhead = $(tbl).find('thead')
        var tblbody = $(tbl).find('tbody')
        var tblbodyCount = $(tbl).find('tbody>tr').length;
        var header = [];
        var JObjectArray = [];
        $.each($(tblhead).find('tr>th'), function (i, j) {
            header.push($(j).text())
        })
        $.each($(tblbody).find('tr'), function (key, value) {
            var jObject = {};
            for (var x = 0; x < header.length; x++) {
                jObject[header[x]] = $(this).find('td').eq(x).text()
            }
            JObjectArray.push(jObject)
        });
        var jsonObject = {};
        jsonObject["count"] = tblbodyCount
        jsonObject["value"] = JObjectArray;
        return jsonObject;
    }

    exportFile(data: Array<any>, exportedColumns: any, exportFileName: string, fileFormat: string, changeKey: boolean = true, orientation: string = '') {
        debugger;
        let mapped = []; 
       if(data.length>0 && (exportFileName=="Detail-List"  || exportFileName=="ForeCast-List")){
        data.forEach(element => {
            if(element["Required Quantity"]!=undefined && element["Required Quantity"]!=null && element["Required Quantity"]!='')
                element["Required Quantity"]=Number(element["Required Quantity"]);
           if(element["Declining Balance"]!=undefined && element["Declining Balance"]!=null && element["Declining Balance"]!='')
                element["Declining Balance"]=Number(element["Declining Balance"]);
            if(element["Current Balance On Hand"]!=undefined && element["Current Balance On Hand"]!=null && element["Current Balance On Hand"]!='')
                element["Current Balance On Hand"]=Number(element["Current Balance On Hand"]);
       });
    }
        if (fileFormat == 'pdf') {
            mapped = data;
        } else {
            let exprtcsv: Array<any> = data;
            let keyArry = Object.keys(exportedColumns);
            if (keyArry.length > 0) {
                let filter_column = _.map(exprtcsv, _.partialRight(_.pick, keyArry));
                if (changeKey) {
                    mapped = filter_column.map(function (obj) {
                        return _.mapKeys(obj, function (value, key) {
                            return exportedColumns[key];
                        });
                    });
                } else {
                    mapped = filter_column;
                }
            } else {
                mapped = exprtcsv;
            }
        }
        this.exportFileService.downloadFile(mapped, exportFileName, fileFormat, exportedColumns, orientation);
    }

    closeLoader() {
        this.isPageLading.next(false);
    }

    loader() {
        this.isPageLading.next(true);
    }

    replaceState(path) {
        this._location.replaceState(path);
    }

    back() {
        this._location.back();
    }

}
