import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'loader-wrapper',
    templateUrl: './loader-wrapper.component.html',
    styleUrls: ['./loader-wrapper.component.scss']
})

export class LoaderWrapperComponent implements OnInit {

    constructor() {}

    ngOnInit() {}
}
