import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from '../../../shared/services/common.service';
import * as $ from 'jquery';

@Component({
  selector: 'export-table',
  templateUrl: './export-table.component.html',
  styleUrls: ['./export-table.component.scss']
})

export class ExportTableComponent implements OnInit {

  @Input() data;
  @Input() name;
  @Input() tableKey;
  @Input() tableKeyCSV;
  @Input() tableKeyPDF;
  @Input() orientation;
  @Input() changeKey;
  @Input() isPageSizeReq;
  @Input() tableID;

  constructor(public commonService: CommonService) {
  }

  ngOnInit() {

  }

  /**
   *Accepted file format 
   csv, xlsx
   **/
  exportFile(data: any = [], name: string, tableKey = {}, fileType: string, changeKey: boolean = true, orientation: string = '') {
    if (data.length > 0) {
            if (this.isPageSizeReq == 'true') {
        data = this.commonService.makeJsonFromTable(this.tableID)['value'];
      }
      this.commonService.exportFile(data, tableKey, name, fileType, changeKey, orientation);
    } else {
      this.commonService.openSnackBar({
        data: {
          message: 'No Records Found',
          status: 'Error',
          action: ' ',
          duration: 2600
        }
      });
    }
  }
}
