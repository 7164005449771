import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { LoggerService } from './shared/logger.service';
import { NavComponent } from './nav/nav.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { SharedModule } from '../shared/modules/shared.module';
import { RouterModule } from '@angular/router';
//import {SearchBarComponent} from './search-bar/search-bar.component';
import { Error404Component } from './error404/error-404.component';
import { ProgressBarService } from './shared/progress-bar.service';
import { MenusComponent } from './menus/menus.component';
import { AvatarComponent } from '../shared/component/avatar-letter/avatar-letter.component';
import { OuterSidebarComponent } from './outer-sidebar/outer-sidebar.component';
//import { ToaterComponentComponent } from '../shared/component/toaster-with-component/toaster-with-component';
import { ToaterComponentSingleLineComponent } from '../shared/component/toaster-with-component-single-line/toaster-with-component-single-line';

import { MatMenuModule} from '@angular/material/menu';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatMenuModule,
    SharedModule,
    ReactiveFormsModule
  ],
  exports: [
    NavComponent,
    SidebarComponent,
    FooterComponent,
    AvatarComponent,
    OuterSidebarComponent,
    //ToaterComponentComponent,
    ToaterComponentSingleLineComponent
  ],
  declarations: [
    NavComponent,
    SidebarComponent,
    FooterComponent,
    //SearchBarComponent,
    Error404Component,
    MenusComponent,
    AvatarComponent,
    OuterSidebarComponent,
    //ToaterComponentComponent,
    ToaterComponentSingleLineComponent
  ],
  providers: [
    LoggerService,
    ProgressBarService
  ],
  entryComponents: [
    //ToaterComponentComponent,
    ToaterComponentSingleLineComponent
  ]
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
