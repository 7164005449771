import { Component, Inject, OnInit, HostListener } from '@angular/core';
import { APP_CONFIG } from '../../config/app.config';
import { IAppConfig } from '../../config/iapp.config';
import { ProgressBarService } from '../shared/progress-bar.service';
import { Router ,RoutesRecognized } from '@angular/router';
import { NavService } from '../shared/nav.service';
import { PageMenus } from '../../pages/pages.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../services/UserService';
import {MsalService} from '../../services/msal.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
  navMode: string = 'side';
  isOpen: boolean = true;
  appConfig: any;
  menuItems: any[];
  progressBarMode: string;
  currentLang: string;
  objUserdetail:any;
  MenuOptions: any[] = [];
  isSpecialCondition: boolean;
  isSidebarEnable: boolean;
  PAGES_MENU = [];
  menu:any;
  previousUrl: string;
  hidecomponent:boolean=false;
  
  constructor(@Inject(APP_CONFIG) appConfig: IAppConfig,
    private progressBarService: ProgressBarService,
    private router: Router,
    private navService: NavService,
    private translateService: TranslateService,
    private userService: UserService,
    private pageMenus: PageMenus,
    private msalService: MsalService
  ) {
    if (window.location.href.indexOf("print") != -1)
    {
       this.hidecomponent=true; 
      
    }
    else{ this.hidecomponent=false;
    }

    this.previousUrl=  localStorage.getItem('previousRoute');
    this.appConfig = appConfig;
    this.PAGES_MENU = this.pageMenus.getDefaultMenus();
  }

    ngOnInit() {
    if(this.msalService.isLoggedIn()){
      this.MenuOptions= this.userService.getMenus();
    //this.getMenuItemsSource();

    this.userService.isSidebarEnable.subscribe((enable: boolean) => {
      Promise.resolve(null).then(() => this.isSidebarEnable = enable);
    });
    
    this.userService.isSpecialCondition.subscribe((mode: boolean) => {
      Promise.resolve(null).then(() => {this.isSpecialCondition = mode;  this.isSidebarEnable = !mode} );
    });
    if (window.innerWidth < 768) {
      this.navMode = 'over';
      this.isOpen = false;
    }
      this.navService.getSideNavState().subscribe((item) => {
        this.isOpen = !item;
      });
      this.loadMenus();
    }
  }
  
  legalSignOffCheck(){
    var objUser= JSON.parse(localStorage.getItem('CurrentUser'));
    if(objUser!=undefined && objUser!=null ){
     if(objUser.role_id!=undefined && objUser.role_id===2 && objUser.legalSignOffEnable==0)
              this.router.navigate(['/legalsignOff']);
        else { 

          if((JSON.parse(localStorage.getItem('loginAsSupplier'))==1 || objUser.role_id==2 )&& window.location.href.indexOf("print") == -1){
            this.isOpen = true;
          this.navMode = 'side';
          this.isOpen = true;
          this.isSidebarEnable=true;
          this.router.navigate(['/home']);
         }
        }
  }
  else{
    this.msalService.internallogout();
  }
    
  }
  private loadMenus(): void {
    if(this.MenuOptions!=null && this.MenuOptions.length>0){
    this.translateService.get(['general'], {}).subscribe((texts: any) => {
        this.menuItems = [];
        let menuItems = this.PAGES_MENU.filter(item =>
          this.MenuOptions.find(o => o.key == item.path)
        );

        menuItems.forEach((item) => {
          this.MenuOptions.forEach((option) => { 
            if (option.key === item.path) {
              if (item['children'] && option['children'] && option['children'].length > 0) {
                let childs = [];
                item.children.forEach(child => {
                  if (option.children.find(o => o.key == child.path)) {
                    child.data.menu.title = child.data.menu.title;
                    childs.push(child);
                  }
                });
                item.data.menu.title = item.data.menu.title;
                item.children = childs;
                this.menuItems.push(item);
              } 
              else {
                if (item['children']) {
                  delete item.children;
                }
                this.menuItems.push(item);
              }
            }
          });
        });
      
    });
    this.legalSignOffCheck();
  }
}

  goToPage(pagename) {
    this.navService.setCurrentNavItem(pagename);
    this.router.navigateByUrl('/' + pagename);
  }

  changeOfRoutes() {
    let list = this.progressBarService.list();
    if (list != 0) {
      for (let i = 0; i < list; i++) {
        this.progressBarService.decrease();
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 768) {
      this.navMode = 'over';
      this.isOpen = false;
    }
    if (event.target.innerWidth > 768) {
      this.navMode = 'side';
      this.isOpen = true;
    }
  }
}